import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "../../.cache/gatsby-browser-entry"
import "../components/highlightcard.css";


export const FileLink = ({ element, tag }) =>
{
	if (element) {
		return <a href={element} className="btn btn-outline-dark btn-sm me-2" target="__blank">{tag}</a>;
	}

	return null;
}

export const ExternalLink = ({ element, tag }) =>
{
	if (element) {
		return <a href={element} className="btn btn-outline-dark btn-sm me-2" target="__blank">{tag}</a>;
	}

	return null;
}

export const InternalLink = ({ element, tag }) =>
{
	if (element) {
		return <Link to={element} className="btn btn-outline-dark btn-sm me-2">{tag}</Link>
	}

	return null;
}

export const ProjectPageLink = ({ project, slug, tag }) =>
{
	if (project) {
		if (project.ext === ".md") {
			return <InternalLink element={slug} tag={tag} />
		} else {
			return <ExternalLink element={project} tag={tag} />
		}
	}

	return null;
}

export const is_external = ({project}) =>
{
	if (project)
	{
		return project.ext !== ".md";
	}
	else
	{
		return false;
	}

}

export const PaperHeadline = ({ paper, title }) =>
{
	if (paper.frontmatter.project)
	{
		if (is_external(paper.frontmatter.project))
		{
			return <a href={paper.frontmatter.slug}
					  className="text-decoration-none"
					  target="__blank">
				{title}
			</a>
		}
		else
		{
			return <Link to={paper.frontmatter.slug}
						 className="text-decoration-none">
				{title}
			</Link>
		}
	}
	else if (paper.frontmatter.arxiv)
	{
		return <a href={paper.frontmatter.arxiv}
				  className="text-decoration-none"
				  target="__blank">
			{title}
		</a>
	}
	else if (paper.frontmatter.pdf)
	{
		return <a href={paper.frontmatter.pdf}
				  className="text-decoration-none"
				  target="__blank">
			{title}
		</a>
	}

	return paper.frontmatter.title;
}

export default function Research ({ data }) {
	const papers = data.papers.nodes;

	return (
		<Layout>
			<SEO title="Research" />

			{/* Publications */}
			<h2 className="text-center" style={{marginBottom: 20}}>Publications</h2>

			<div className="container-fluid mx-lg-auto mx-0">
				{papers.map(paper => (
					<div className="row highlight-card rounded border-0 g-0 g-md-4 my-md-4" id={paper.frontmatter.slug} key={paper.frontmatter.slug}>
						<div className="col-12 col-md-4 mb-3 mb-md-4 mb-lg-0 mt-2 mt-md-0  d-md-block d-none">
							<PaperHeadline paper={paper} title={<Img fluid={paper.frontmatter.thumb.childImageSharp.fluid}
																	 style={{maxWidth: 350}} className="mx-auto"/>}/>

						</div>
						<div className="col-12 col-md-8">
							<div className="text-center text-md-start fs-3">
								{/*{paper.frontmatter.title}*/}
								<PaperHeadline paper={paper} title={paper.frontmatter.title}/>
							</div>
							{/*<h6 dangerouslySetInnerHTML={{__html: paper.frontmatter.authors}}>{paper.frontmatter.authors}</h6>*/}
							<div className="text-muted text-center text-md-start"
									 dangerouslySetInnerHTML={{ __html: paper.frontmatter.authors }}/>
							<div className="d-md-none d-block my-1 text-center">
								<Img fluid={paper.frontmatter.thumb.childImageSharp.fluid}
										 className="mx-4 p-2 rounded"/>

							</div>

							<p className="my-0 my-md-2 text-center text-md-start">
								<em>{paper.frontmatter.conference} ({paper.frontmatter.conference_short})
									- {paper.frontmatter.year}</em></p>
							<div className="text-center text-md-start">
								<ProjectPageLink project={paper.frontmatter.project} slug={paper.frontmatter.slug} tag={"Project"}/>
								<FileLink element={paper.frontmatter.pdf} tag={"PDF"}/>
								<ExternalLink element={paper.frontmatter.arxiv} tag={"Arxiv"}/>
								<ExternalLink element={paper.frontmatter.youtube} tag={"Video"}/>
								<ExternalLink element={paper.frontmatter.code} tag={"Code"}/>
								<FileLink element={paper.frontmatter.bibtex} tag={"BibTex"}/>
								{/*<a href="#" className="btn btn-outline-dark btn-sm me-2">Project</a>*/}
							</div>
						</div>
						<div className="d-block d-md-none my-1 my-md-0">
							<hr/>
						</div>
					</div>
				))}

			</div>
		</Layout>
	);
}


export const query = graphql`
query ResearchPage {
  papers: allMarkdownRemark(
  	sort: {fields: frontmatter___date, order: DESC}
  	filter: {fileAbsolutePath: {regex: "/(src/papers)/"}}
  ) {
		nodes {
			frontmatter {
				title
				conference
				conference_short
				slug
				year
				authors
				thumb {
				  childImageSharp {
				  	fluid {
						...GatsbyImageSharpFluid
					}
					fixed {
						...GatsbyImageSharpFixed
					}
				  }
				}
				pdf
				bibtex
				code
				arxiv
				youtube
				project {
				id
				ext
				name
				}
			}
			id
		}
	}

}
`

